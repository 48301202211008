var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classDiv1 },
    [
      _c(
        "sig-button-info",
        { attrs: { text: _vm.observation, title: _vm.label } },
        [
          _c(
            "label",
            {
              staticClass: "vs-input--label",
              attrs: { for: "", color: "danger" },
            },
            [_vm._v("\n      " + _vm._s(_vm.label))]
          ),
        ]
      ),
      _c(
        "vs-switch",
        {
          attrs: { disabled: _vm.disabled },
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        },
        [
          _c("span", { attrs: { slot: "off" }, slot: "off" }, [
            _vm._v(_vm._s(_vm.onLabel)),
          ]),
          _c("span", { attrs: { slot: "on" }, slot: "on" }, [
            _vm._v(_vm._s(_vm.offLabel)),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }