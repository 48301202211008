import { Model, configure } from '@/store/model/model'
import _ from 'lodash'
import moment from 'moment'
/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class ObservationStudent extends Model {}

configure(model, {
  $fields: {
    id: {
      label: 'Registro',
      grid: true
    },
    sendDate: {
      label: 'Data Envio',
      grid: true,
      format (e) {
        if (e.document) return moment(e.document.send_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }
    },
    response: {
      label: 'Retorno',
      grid: true
    },
    name: {
      label: 'Destinatário',
      grid: true,
      format (e) {
        if (e.document) return e.document.name
      }
    },
    situation: {
      label: 'Situação',
      grid: true
    },
    service_package: {
      label: 'Serviço',
      grid: true
    },
    observation: {
      label: 'Observação',
      grid: true,
      columnDef: {
        autoHeight: true,
        wrapText: true,
        cellStyle: {'white-space': 'normal'}
      }
    },
    cnh_category: {
      label: 'Categoria'
    },
    document_id: {},
    student_id: {},
    user_id: {
      label: 'Criador',
      grid: true,
      format (e) {
        return e.user ? e.user.name : ''
      }
    },
    created_at: {
      label: 'Criado em',
      grid: true,
      format (e) {
        if (e.created_at) return moment(e.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }
    }
  },
  $store: {
    name: 'studentDocument',
    actionName: 'students/document',
    actions: {
      fetchStudent: 'fetchStudent'
    },
    dispatch: {
      fetchStudent: 'fetchStudent'
    }
  },
  $grid: {
    actions: {
      edit: true,
      select: false,
      delete: false
    }
  },
  $form: {
    disabled: true
  },
  $name: 'studentDocument'
})

export default new model()
