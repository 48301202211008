import state from './moduleAccountPlanState.js'
import mutations from './moduleAccountPlanMutations.js'
import actions from './moduleAccountPlanActions.js'
import getters from './moduleAccountPlanGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

