<template>
    <div>
          <label class="vs-input--label">{{ label }}</label>
          <vs-input-number min="1" class="mt-2" v-model="localValue" :max="max"/>
      </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: {
      default: 'Número'
    },
    disabled: {},
    validate: {},
    max: {}
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
