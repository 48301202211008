import axios from '@/axios.js'


function fetchAll ({ commit }) {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.VUE_APP_API_URL}/register/group`)
      .then((response) => {
        commit('SET_GROUPS', response.data)
        resolve(response)
      })
      .catch((error) => { reject(error) })
  })
}


export default {
  save ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/register/group`, payload)
        .then((response) => {
          resolve(response)
          fetchAll({ commit })
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchAll,
  remove ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/register/group/${payload.id}`)
        .then((response) => {
          resolve(response)
          fetchAll({ commit })
        })
        .catch((error) => { reject(error) })
    })
  }

}
