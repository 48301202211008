import moment from 'moment'

export default {
  dateLessOrEqualThan: {
    validator:  (value, [otherValue]) => {
      const date1 = moment(value)
      const date2 = moment(otherValue)
      if (!date1.isValid() || !date2.isValid()) {
        return false
      }
      return date1.diff(date2) <= 0
    },
    params: {
      hasTarget: true
    }
  },
  dateGreaterOrEqualThan: {
    getMessage (field, args) {
      return `Data deve ser menor que a ${args.date}` 
    },
    validator:  (value, [otherValue]) => {
      return moment(value).diff(moment(otherValue)) >= 0
    },
    params: {
      hasTarget: true
    }
  },
  maxDate: {
    validator:  (value, args) => {
      const date1 = moment(value)
      const date2 = moment(args[0])
      if (!date1.isValid() || !date2.isValid()) {
        return false
      }
      return date1.diff(date2, args[3]) <= args[1]
      
    },
    params: {
      hasTarget: true
    }
  }
}