import typeDownPay from './typeDownPay'
import typePaymentSig from './typePayment'
import financialCard from './financialCard'
import accountSig from './account'
import accountPlanSig from './accountPlan'
import parcel from './parcel'
import specie from './specie'
import interest from './interest'

export default {
  typeDownPay,
  financialCard,
  accountSig,
  accountPlanSig,
  typePaymentSig,
  parcel,
  specie,
  interest
}