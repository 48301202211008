import { Model, configure } from '@/store/model/model'
import typeDownPay from '../typeDownPay/model'
import parcel from '../parcel/model'
import financialCard from '../financialCard/model'
import account from '../account/model'
import specie from '../specie/model'

/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class TypePayment extends Model {
  constructor (args) {
    super(args)
    this.auto_down = this.type_down_pay_id
    this.allow_divide = this.parcels.length
    this.interest = Number(this.interest)
    this.addition = Number(this.addition)
  }

  $labelName () {
    switch (this.type) {
    case 'CREDIT_CARD':
    case 'DEBIT_CARD':
      return 'Bandeira'
    case 'MONEY': return 'Destino do dinheiro'
    case 'BANKSLIP': return 'Descriçao'
    case 'TRANSFER': return 'Nome da Conta'
    case 'PIX': return 'Chave do PIX'
    case 'CARNE': return 'Descrição'
    case 'ALL': return 'Descriçao'
    }
  }

  $getParcelByNumber (number) {
    if (this.parcels.length === 0) return

    const list = this.parcels.filter(element => {
      return element.pivot.number == number
    })
    if (list.length > 0) return list[0]
  }

  $fill (state) {
    super.$fill(state)
    this.parcels.forEach((element, key) => {
      element.number = key + 1
    })
  }

  $setValue (value) {
    this.$value = Number(value)
  }

  $addition () {
    const value = this.$value
    if (!this.addition) {
      return 0
    }
    return (this.type_addition === '%' ? value *  (this.addition / 100).toFixed(2) : this.addition).toFixed(2)
  }

  $fee () {
    const value = this.$value
    if (!this.fee) {
      return 0
    }
    return (this.type_fee === '%' ? (value / (1 - (this.fee / 100)).toFixed(2)) - value : this.fee).toFixed(2)
  }

  $calculate () {
    let value = this.$value
    if (this.addition) {
      value += this.$addition()
    }
    if (this.fee && this.fee_comsumer) {
      value += this.$fee()
    }
    return value.toFixed ? value.toFixed(2) : value
  }
}

configure(model, {
  $fields: {
    type: {
      label: 'Tipo de Pagamento',
      grid: true,
      enum: specie.$fields.value.enum,
      format (element) {
        return this.enum[element.type] || 'INDEFINIDO'
      },
      options: specie.$fields.value.options
    },
    type_addition: {
      label: 'Tipo Acréscimo',
      default: '%',
      options: [
        {
          label: 'NUMÉRICO',
          value: '$'
        },
        {
          label: 'PERCENTUAL',
          value: '%'
        }
      ]
    },
    account_id: {},
    addition: {
      label: 'Valor Acréscimo',
      default: 0
    },
    company_id: {},
    created_at: {},
    deleted_at: {},
    id: {},
    interest: {
      label: 'Valor Taxa',
      default: 0
    },
    maximum_discount: {
      label: 'Desconto Máximo',
      default: 0
    },
    interest_comsumer: {
      label: 'Cliente Paga Taxa'
    },
    limit_divide: {
      label: 'Limite Parcelas'
    },
    fee_per_parcel:{
      label: 'Parcelar Taxa, Se existir',
      default: false
    },
    species_id: {},
    species_type: {},
    type_down_pay_id: {},
    type_interest: {
      label: 'Tipo Taxa',
      default: '%',
      options: [
        {
          label: 'NUMÉRICO',
          value: '$'
        },
        {
          label: 'PERCENTUAL',
          value: '%'
        }
      ]
    },
    type_maximum_discount: {
      label: 'Tipo Desconto Máximo',
      default: '%',
      options: [
        {
          label: 'NUMÉRICO',
          value: '$'
        },
        {
          label: 'PERCENTUAL',
          value: '%'
        }
      ]
    },
    type_payment: {
      label: 'Descrição',
      validate: 'required|min:3',
      width: 300,
      grid: true
    },
    updated_at: {},
    // so exite no js
    has_addition: {
      label: 'Acréscimo'
    },
    allow_divide: {
      label: 'Permite Parcelar'
    },
    auto_down: {
      label: 'Quitar Automaticamente'
    }
  },
  $relations: {
    account: {
      model: account,
      required: true,
      foreign: 'account_id',
      message: 'Selecione uma Conta'
    },
    type_down_pay: {
      model: typeDownPay,
      foreign: 'type_down_pay_id'
    },
    financial_card: {
      model: financialCard,
      property: 'financialCard',
      foreign: 'species_id'
    },
    parcels: {
      model: parcel,
      property: 'parcels'
    }
  },
  $store: {
    name: 'typePaymentSig',
    actionName: 'financial/typePayment',
    getters: {
      selectedspecie: 'selectedspecie'
    }
  },
  $select: {
    label: 'Selecione a Espécie',
    name: 'Tipo',
    description: ['type_payment'],
    options: 'type_payment'
  },
  $form: {
    name: 'Tipo de Pagamento'
  },
  $name: 'typePayment'
})

export default new model()
