import Vue from 'vue'

export default {
  STORE (state, payload) {
    state.services.push(payload)
  },
  UPDATE (state, payload) {
    const index = state.services.findIndex((o) => o.id === payload.id)
    Vue.set(state.services, index, payload)
  },
  SET_SERVICES (state, services) {
    state.services = services
  },
  MAKE_EDITABLE (state, payload) {
    const services = state.services[payload]
    services.edit = true
    Vue.set(state.services, payload, services)
  },
  MAKE_UNEDITABLE (state, payload) {
    const services = state.services[payload]
    services.edit = false
    Vue.set(state.services, payload, services)
  },
  DELETE (state, payload) {
    state.services.splice(payload, 1)
  }

}
