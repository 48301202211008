import { Model, configure } from '@/store/model/model'
import _ from 'lodash'

/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class Student extends Model {}
configure(model, {
  $fields: {
    id: {},
    name: {},
    registration_date: {},
    cpf: {},
    rg: {},
    orgao_emissor_rg: {},
    orgao_emissor_rg_uf_id: {},
    status: {},
    type: {},
    category_pearson: {},
    date_of_birth: {},
    mothers_name: {},
    fathers_name: {},
    nationality: {},
    gender: {},
    occupation: {},
    workplace: {},
    education: {},
    extra_field: {},
    lembrete: {},
    type_of_communication: {},
    facebook: {},
    instagram: {},
    other_social_media: {},
    state_nasc_id: {},
    city_nasc_id: {},
    marital_status: {},
    observation: {},
    renach_form_number: {},
    opening_date_renach_form_number: {},
    date_expiration_renach_form_number: {},
    cnh_printed_digital: {},
    ladv_emission: {},
    gainful_activity: {},
    registro_cnh: {},
    formulario_cnh: {},
    date_first_cnh: {},
    date_last_cnh: {},
    validity_cnh: {},
    current_category_cnh: {},
    intended_category_cnh: {},
    type_cnh: {},
    intended_services: {},
    city_cnh_id: {},
    state_cnh_id: {},
    responsible_registration_id: {},
    company_id: {},
    deleted_at: {},
    created_at: {},
    updated_at: {},
    account_plan_id: {}
  },
  $store: {
    name: 'studentSig',
    actionName: 'register/student'
  },
  $select: {
    label: 'Selecione um Estudante',
    name: 'Estudante',
    description: ['name'] // campos para mostrar no botao do SigSelect
  },
  $grid: {
    actions: {
      edit: false
    }
  },
  $name: 'student'
})

export default new model()
