import _ from 'lodash'

export default function (config = {}) {
  const getters = {}

  if (config.select) {
    _.each(config.select, (element, key) => {
      if (key !== 'name') {
        getters[`selected${key}`] = state => {
          return state.select[key].selected
        }
      }
    })
  }
  getters.form = state => { return state.form.isOpen }
  getters.popup = state => { return state.form.popup }
  getters.options = state => { return state.options }
  return getters
}