import axios from '@/axios.js'
import _ from 'lodash'

export default function (model) {
  const  fetchGrid = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}${model.$store.action.fetchGrid}`, payload)
        .then((response) => {
          // data.data é a lista de registros retornados
          // data.total é o número da quantidade de registros
          // aqui eu converto json em Model
          _.each(response.data.data, (element, key) => {
            response.data.data[key] = new model.constructor(element)
          })
          commit(model.$store.mutation.fetchAdd, response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
      
  const remove = ({ commit, state}) => {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}${model.$store.action.delete}${state.selectDelete.id}`)
        .then((response) => {
          commit(model.$store.mutation.delete)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }

  return {
    delete: remove,
    fetchGrid,
    saveSelected ({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.select.name) return
        const data = []
        state.select[state.select.name].selected.forEach(element => {
          data.push(element.$serialize())
        })
        
        axios.post(`${process.env.VUE_APP_API_URL}${model.$store.action.saveSelected}`, data)
          .then((response) => {
            commit(model.$store.mutation.fetchGridAdd, response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    saveSingle ({ commit, state }) { // salvar no servidor o state.single
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}${model.$store.action.saveSingle}`, state.single.$serialize())// serialize remove propriedes nao utilizadas
          .then((response) => {
            commit(model.$store.mutation.fetchGridAdd, response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    }
  }
}
