import axios from '../../../axios/index.js'
import store from '../../../../store/store.js'
import router from '@/router'
import JsonWebToken from 'jsonwebtoken'

// Token Refresh
let isAlreadyFetchingAccessToken = false
// let subscribers = []

// function onAccessTokenFetched (access_token) {
//   subscribers = subscribers.filter(callback => callback(access_token))
// }

// function addSubscriber (callback) {
//   subscribers.push(callback)
// }

export default {
  init () {
    // Request interceptor
    axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem('accessToken')
        if (token && !axios._noHeaders) {
          config.headers['Authorization'] = `Bearer ${token}`
        } else {
          delete config.headers['Authorization']
          delete config.headers.common['Authorization']
        }
        config.headers['Content-Type'] = 'application/json'

        //a propriedade _noHeaders setada como true ou aualquer outro valor é utilizada para consultas sem Autorization
        //devo deletar ela sempre após uma consulta sem autorização para que as próximas sejam autorizadas
        axios._noHeaders = false
        return config
      },
      error => {
        Promise.reject(error)
      })

    // Response interceptor
    axios.interceptors.response.use(function (response) {

      if (response.data && response.data.message === 'commercialProposal') {
        router.push('/commercial-proposal').catch(() => {})
      }

      if (localStorage.getItem('accessToken')) {
        const token = JsonWebToken.decode(localStorage.getItem('accessToken'))
        const now = Date.now() / 1000 // exp is represented in seconds since epoch
        const timeToRefresh = (token.exp) - (30 * 60) // 30 MINUTOS ANTES DE EXPIRAR (api expira o token em 2 horas)
        //ATUALIZAR TOKEN
        if (now > timeToRefresh && !isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch('auth/fetchAccessToken')
            .then(() => {
              // onAccessTokenFetched(access_token)
            })
            .catch((err) => {
              console.error(err)
            })
        }
      }
      return response
    }, function (error) {
      // const { config, response: { status } } = error
      const { response } = error
      // const originalRequest = config
      if (response && (response.status === 401 || response.status === 403)) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userInfo')
        if (response.data.message === 'blockedCompany') {
          router.push('/blocked-company').catch(() => {})
        } else if (response.data.message === 'blockedUser') {
          router.push('/blocked-user').catch(() => {})
        } else if (response.data.message === 'Your email address is not verified.') {
          router.push('/check-email').catch(() => {})
        } else {
          // // Change role on logout. Same value as initialRole of acj.js
          // $acl.change('admin')
          router.push('/login').catch(() => {})
        }
      }
      return Promise.reject(error)
    })
  },

  login (email, pwd) {
    return axios.post(`${process.env.VUE_APP_API_URL}/auth/login`, {
      email,
      password: pwd
    })
  },
  // registerUser (name, email, pwd) {
  //   return axios.post(`${process.env.VUE_APP_API_URL}/auth/register`, {
  //     displayName: name,
  //     email,
  //     password: pwd
  //   })
  // },
  refreshToken () {
    return axios.post(`${process.env.VUE_APP_API_URL}/auth/refresh`)
  },
  logout () {
    return axios.post(`${process.env.VUE_APP_API_URL}/auth/logout`)
  }
}
