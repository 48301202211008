export default {
  forSelect (state) {
    const accountPlans = []
    for (const k in state.accountPlans) {
      accountPlans.push({
        value: state.accountPlans[k].id,
        label: state.accountPlans[k].plan
      })
    }
    return accountPlans
  },

  forSelectFilterReceipt (state, getters) {
    const newNode = JSON.parse(JSON.stringify(getters.forTree))
    const root = []
    const tree = newNode.filter(t => t.plan === 'RECEITA')
    const f = function (tree) {
      tree.forEach(node => {
        node.value = node.id
        node.label = node.plan
        root.push(node)
        if (node.children) {
          f(node.children)
        }
      })
    }
    if (tree.length) f(tree)
    return root
  },

  forSelectFilterDespesa (state, getters) {
    const newNode = JSON.parse(JSON.stringify(getters.forTree))
    const root = []
    const tree = newNode.filter(t => t.plan === 'DESPESA')
    const f = function (tree) {
      tree.forEach(node => {
        node.value = node.id
        node.label = node.plan
        root.push(node)
        if (node.children) {
          f(node.children)
        }
      })
    }
    if (tree.length) f(tree)
    return root
  },

  forTree (state) {
    // Create root for top-level node(s)
    const root = []
    const newNode = JSON.parse(JSON.stringify(state.accountPlans))
    newNode.forEach(node => {
      node.name = node.plan
      node.pid = node.parent_id
      delete node.childs
      delete node.created_at
      delete node.updated_at
      node.addLeafNodeDisabled = true
      // EVITA EDIÇÃO DE RECEITA E DESPESA
      if (node.name === 'RECEITA' || node.name === 'DESPESA') {
        node.dragDisabled = true
        node.editNodeDisabled = true
        node.delNodeDisabled = true
      }

      if (node.parent_id === 0) return root.push(node)

      // Insert node as child of parent in flat array
      const parentIndex = newNode.findIndex(el => el.id === node.parent_id)
      if (!newNode[parentIndex].children) {
        // eslint-disable-next-line no-return-assign
        return newNode[parentIndex].children = [node]
      }

      newNode[parentIndex].children.push(node)
    })
    return root
  }
}
