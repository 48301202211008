import createActions from '@/store/model/actions'
import axios from '@/axios'
import model from './model'

const actions = createActions(model)

actions.fetchStudent = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.VUE_APP_API_URL}/students/${payload.student_id}/debit`)
      .then((response) => {
        // data.data é a lista de registros retornados
        // data.total é o número da quantidade de registros
        // aqui eu converto json em Model
        _.each(response.data.data, (element, key) => {
          response.data.data[key] = new model.constructor(element)
        })
        commit(model.$store.mutation.fetchAdd, response.data)
        resolve(response)
      })
      .catch((error) => { reject(error) })
  })
}

actions.saveSingle = ({ commit }, payload) => { // salvar no servidor o state.single
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.VUE_APP_API_URL}${model.$store.action.saveSingle}`, payload)// serialize remove propriedes nao utilizadas
      .then((response) => {
        commit(model.$store.mutation.fetchGridAdd, response.data)
        resolve(response)
      })
      .catch((error) => { reject(error) })
  })
}

export default actions
