import { Model, configure } from '@/store/model/model'
import _ from 'lodash'

/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class Account extends Model {}

configure(model, {
  $fields: {
    id: {},
    account: {
      label: 'Nome',
      grid: true
    },
    bank_account: {},
    bank_number: {},
    bank_agency: {},
    balance: {},
    default_interest_type: {},
    default_interest: {},
    default_interest_days: {},
    default_fee_type: {},
    default_fee: {},
    default_fee_days: {},
    bank_account_id: {},
    card_account_id: {},
    external: {},
    company_id: {},
    deleted_at: {},
    created_at: {},
    updated_at: {}
  },
  $store: {
    name: 'accountSig',
    actionName: 'financial/account'
  },
  $select: {
    label: 'Conta',
    name: 'Conta',
    description: ['account']
  },
  $grid: {
    actions: {
      edit: false
    }
  },
  $form: {
    disabled: true
  },
  $name: 'account'
})

export default new model
