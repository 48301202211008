import { Model, configure, generateOptionsEnum } from '@/store/model/model'

const balanceType = generateOptionsEnum({
  DAILY: 'Dia'
}, {
  WEEKLY: 'Semana'
}, {
  BIWEEKLY: 'Quinzena'
}, {
  MONTHLY: 'MÊS'
}, {
  QUARTERLY: 'Trimestre'
}, {
  'SEMI-ANNUAL': 'Semestre'
}, {
  ANNUAL: 'Ano'
})

/**
 * Lembre de registrar o store em index.js
 */

const model = class ConfigCashier extends Model {
  constructor (args) {
    super(args)
  }

  $getComponent (field) {
    switch (field.type) {
    default:
      return 'vs-input'
    }
  }
}

configure(model, {
  $fields: {
    id: {},
    allow_delete: {
      label: 'Permite excluir',
      default: 1
    },
    individual: {
      label: 'Caixa indiviual',
      default: 1
    },
    allow_cancel: {
      label: 'Permite cancelar',
      default: 1
    },
    allow_edit: {
      label: 'Permite editar',
      default: 1
    },
    show_chageback: {
      label: 'Mostrar estornos'
    },
    show_moviment_header: {
      label: 'Mostrar movimentações',
      default: 1
    },
    sum_chageback_day: {
      label: 'Somar estornos do dia',
      default: 1
    },
    split_income_expense: {
      label: 'Separar receitas e despesas',
      default: 1
    },
    list_fields: {
      label: 'Mostrar campos',
      options: [
        { value: 'account_id', label: 'Conta' },
        { value: 'account_plan_id', label: 'Plano de conta' },
        { value: 'boleto_tax_id', label: 'Taxa boleto' },
        { value: 'contract_id', label: 'Contrato' },
        { value: 'discount', label: 'Desconto' },
        { value: 'doctor_id', label: 'Médico' },
        { value: 'expiration_date', label: 'Vencimento' },
        { value: 'observation', label: 'Observação' },
        { value: 'parcel', label: 'Parcela' },
        { value: 'payday', label: 'Pagamento' },
        { value: 'situation', label: 'Situação' },
        { value: 'type', label: 'Tipo' },
        { value: 'type_payment_id', label: 'Espécie' },
        { value: 'user_id', label: 'Usuário' },
        { value: 'value_parcel', label: 'Valor' },
        { value: 'value_pay', label: 'Pago' },
        { value: 'value_total', label: 'Total' }
      ]
    },
    balance_type: {
      label: 'Período do balanço',
      default: 'MONTHLY',
      format (me) {
        return me.$fields.balance_type.enum[me.balance_type]
      },
      options: balanceType.options,
      enum: balanceType.enum
    },
    balance_start: {
      default: 1
    },
    company_id: {},
    created_at: {},
    updated_at: {}
  },
  $store: {
    name: 'configCashier',
    actionName: 'financial/configCashier',
    actions: {
      single: 'single'
    },
    dispatch: {
      single: 'single'
    }
  },
  $name: 'configCashier'
})

export default new model()
