export default{
  add (state, payload) {
    if (!payload.index) {
      let last = null
      if (state.list.length > 0) {
        last = state.list[state.list.length - 1]
        last.close()
      }
      state.list.push(payload)
      setTimeout(() => {
        if (payload) {
          payload.open()
        }
      }, 100) 
    }
  },
  remove (state) {
    let single = state.list.pop()
    if (single) {
      single.close()
      single = state.list[state.list.length - 1]
      setTimeout(() => {
        if (single) {
          single.open()
        }
      }, 100)
    }
  },
  clear (state) {
    state.fetchGrid = []
  }
} 