import { Model, configure } from '@/store/model/model'
import accountPlan from '../accountPlan/model'

/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class Parcel extends Model {
  constructor (args) {
    super(args)
    this.interest = Number(this.interest)
    this.addition = Number(this.addition)
    this.fee = Number(this.fee)
  }

  $setValue (value) {
    this.$value = Number(value)
  }

  $addition () {
    const value = this.$value
    if (!this.addition) {
      return 0
    }
    return Number((this.type_addition === '%' ? value *  (this.addition / 100).toFixed(2) : this.addition).toFixed(2))
  }

  $fee () {
    const value = this.$value
    if (!this.fee) {
      return 0
    }
    return Number((this.type_fee === '%' ? (value / (1 - (this.fee / 100)).toFixed(2)) - value : this.fee).toFixed(2))
  }

  $calculate () {
    let value = this.$value
    if (this.addition) {
      value = value +  this.$addition()
    }
    if (this.fee && this.fee_comsumer) {
      value += this.$fee()
    }
    return Number(value.toFixed(2))
  }
}

configure(model, {
  $fields: {
    addition: {
      label: 'Valor Acréscimo',
      validate:'required',
      default: 0,
      grid: true,
      format (element) {
        return element.type_addition === '%' ? `${element.addition}%` : `R$ ${element.addition}`
      }
    },
    type_addition: {
      label: 'Tipo Acréscimo',
      default: '%',
      options: [
        {
          label: 'NUMÉRICO',
          value: '$'
        }, {
          label: 'PERCENTUAL',
          value: '%'
        }
      ]
    },
    company_id: {},
    created_at: {},
    id: {},
    mode_interest: {
      label: 'Soma Juros',
      default: 'DAY',
      options: [
        {
          label: 'DIA',
          value: 'DAY'
        }, {
          label: 'MÊS',
          value: 'MONTH'
        }
      ],
      format (element) {
        return element.mode_interest === 'DAY' ? 'por dia' : 'por mês'
      }
    },
    interest: {
      label: 'Valor Juros Atraso',
      validate:'required',
      default: 0,
      grid: true,
      format (element) {
        const value =  element.type_interest === '%' ? `${element.interest}%` : `R$ ${element.interest}`
        return `${value} ${element.mode_interest_formated}`
      }
    },
    type_interest: {
      label: 'Tipo Juros',
      default: '%',
      options: [
        {
          label: 'NUMÉRICO',
          value: '$'
        }, {
          label: 'PERCENTUAL',
          value: '%'
        }
      ]
    },
    fee: {
      label: 'Taxa',
      validate:'required',
      default: 0,
      grid: true,
      format (element) {
        return element.type_fee === '%' ? `${element.fee}%` : `R$ ${element.fee}`
      }
    },
    type_fee: {
      label: 'Tipo Taxa',
      default: '%',
      options: [
        {
          label: 'NUMÉRICO',
          value: '$'
        }, {
          label: 'PERCENTUAL',
          value: '%'
        }
      ]
    },
    fee_comsumer: {
      label: 'Cliente Paga Taxa',
      grid: true,
      default: false,
      format (element) {
        return element.fee_comsumer ? 'SIM' : 'NÃO'
      }
    },
    updated_at: {}
    // abaixo so existe no js
  },
  $relations: {
    accountPlan: {
      model: accountPlan,
      required: true,
      foreign: 'account_plan_id',
      message: 'Selecione um Plano de Conta'
    }
  },
  $store: {
    name: 'parcel',
    actionName: 'financial/parcel'
  },
  $select: {
    label: 'Selecione a configuração para cada parcelamento',
    name: 'Parcela',
    description: ['number', 'name', 'interest'] // campos para mostrar no botao do SigSelect
  },
  $form: {
    name: 'Configuração de Parcelamento'
  },
  $grid: {
    actions: {
      edit: false
    }
  },
  $name: 'parcel'
})

export default new model()
