var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vs-input", {
    directives: [
      {
        name: "currency",
        rawName: "v-currency",
        value: _vm.currencyConfig,
        expression: "currencyConfig",
      },
    ],
    ref: "input",
    staticClass: "w-full",
    attrs: { label: _vm.label, disabled: _vm.disabled },
    on: { change: _vm.changeValue },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v
      },
      expression: "inputValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }