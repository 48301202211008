export default {
  getForSelect (state) {
    const services = []
    for (const k in state.services) {
      const type = state.services[k].type === 'TAX' ? 'TAXA' : 'ÍTEM'
      services.push({
        value: state.services[k].id,
        label: `${state.services[k].service} (${type})`,
        type: state.services[k].type,
        val: state.services[k].value
      })
    }
    return services
  }
}
