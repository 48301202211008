var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      attrs: { title: _vm.value.title, active: _vm.isOpen },
      on: {
        "update:active": function ($event) {
          _vm.isOpen = $event
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }