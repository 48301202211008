import axios from '@/axios.js'

export default {
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/account`, payload)
        .then((response) => {
          commit('SET', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/account/${payload.id}`, payload)
        .then((response) => {
          commit('SET', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchAll ({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (!state.accounts.length) {
        axios.get(`${process.env.VUE_APP_API_URL}/accounts`)
          .then((response) => {
            commit('REFRESH_SELECT', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      } else {
        resolve(state.accounts)
      }
    })
  },

  //NO FILTER SEMPRE DEVO FAZER A CONSULTA A API PARA RENOVAR O ESTADO POR COMPLETO
  fetchFilter ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/accounts/filter`, payload)
        .then((response) => {
          commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  delete ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/account/${id}`)
        .then((response) => {
          commit('DELETE', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
