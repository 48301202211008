const situationEnum = {
  PAYED_STUDENT: 'Pago pelo aluno',
  PENDING: 'Pendente'
//  PAYED_CFC: 'Pago pelo CFC'
}

// montar options para o v-select
const situationOptions = []

for (const e in situationEnum) {
  situationOptions.push({
    value: e,
    label: situationEnum[e]
  })
}

export { situationEnum, situationOptions }
