import { Model, configure } from '@/store/model/model'
/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class ReportBankSlip extends Model {}

configure(model, {
  $store: {
    name: 'reportBankSlip',
    getters: {
      url: 'url',
      deliveryUrl: 'deliveryUrl'
    }
  },
  $form: {
    name: 'Boletos'
  },
  $name: 'reportBankSlip'
})

export default new model()
