import { Model, configure } from '@/store/model/model'
import _ from 'lodash'

/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class TypeDownPay extends Model {}
const type = {
  label: 'Tipo de Quitação',
  validate: 'required',
  grid: true,
  width: 300,
  enum: {
    AFTER_MINUTE: 'Depois de um número de minutos',
    AFTER_HOUR: 'Depois de um número de horas',
    AFTER_DAY: 'Depois de um número de dias',
    AFTER_MONTH: 'Depois de um número de meses',
    DAY_MONTH: 'Em um dia específico do mês',
    FIRST_DAY_MONTH: 'Em um dia util especifico do mês'
  },
  format (element) {
    // element é o modelo
    return element.$fields.type.enum[element.type]
  },
  options: [] // usado no v-select
}

// gerar options para o value
_.each(type.enum, (element, key) => {
  type.options.push({
    label: element,
    value: key
  })
})

configure(model, {
  $fields: {
    id: {},
    type,
    value: {
      label: 'Parâmetro',
      labelValue (me) {
        return me.type ? me.$fields.value.auxFormat[me.type].value : 'Parâmetro'
      },
      validate: 'required|numeric|min:1',
      auxFormat: {
        AFTER_MINUTE: {
          after: ' minuto',
          afterPlural: ' minutos',
          label: 'Informe o número de minutos para fazer a quitação',
          value: 'Informe o número de minutos'
        },
        AFTER_HOUR: {
          after: ' hora',
          afterPlural: ' horas',
          label: 'Informe o número de horas para fazer a quitação',
          value: 'Informe o número de horas'
        },
        AFTER_DAY: {
          after: ' dia',
          afterPlural: ' dias',
          label: 'Informe o número de dias para fazer a quitação',
          value: 'Informe o número de dias'
        },
        AFTER_MONTH: {
          after: ' mês',
          afterPlural: ' meses',
          label: 'Informe o número de meses para fazer a quitação',
          value: 'Informe o número de meses'
        },
        DAY_MONTH: {
          after: 'º dia útil',
          label: 'Informe o dia do mês para fazer a quitação',
          value: 'Informe o número do dia do mês'
        },
        FIRST_DAY_MONTH: {
          before: 'Todo dia ',
          after: ' de cada mês',
          label: 'Informe o número do dia util',
          value: 'Informe o número do dia útil do mês'
        }
      },
      format (element) {
        if (element.value && element.type) {
          let msg = ''
          const type = this.auxFormat[element.type]
          msg += type.before ? type.before : ''
          msg += element.value
          if (element.value > 1) {
            msg += type.afterPlural ? type.afterPlural : ''
          } else {
            msg += type.after ? type.after : ''
          }
          return msg
        }
      },
      grid: true
    },
    all: {
      label: 'Quitar Tudo',
      tooltip: 'Se o pagamento for parcelado, vai quitar todas as parcelas no mesmo instante.',
      default: true,
      format (element) {
        return element.all ? 'SIM' : 'NÃO'
      },
      grid: true
    },
    company_id: {},
    deleted_at: {},
    created_at: {},
    updated_at: {}
  },
  $store: {
    name: 'typeDownPay',
    actionName: 'financial/typeDownPay',
    commit: {
      label: 'labelValue'
    }
  },
  $form: {
    name: 'Configuração de Quitação Automática'
  },
  $select: {
    label: 'Selecione uma configuração Quitar',
    name: 'Quitar',
    description: ['name', 'value_formated'] // campos para mostrar no botao do SigSelect
  },
  $grid: {
    actions: {
      edit: false
    }
  },
  $name: 'typeDownPay'
})

export default new model()
