import { Model, configure } from '@/store/model/model'
import _ from 'lodash'

/**
 * Lembre de registrar o store em ../index.js
 */

const model = class Employee extends Model {}
configure(model, {
  $fields: {
  },
  $store: {
    name: 'employeeSig',
    actionName: 'register/employee'
  },
  $select: {
    label: 'Selecione um Funcionário',
    name: 'Funcionário',
    description: ['name'] // campos para mostrar no botao do SigSelect
  },
  $grid: {
    actions: {
      edit: false
    }
  },
  $name: 'employee'
})

export default new model()
