import { Model, configure } from '@/store/model/model'
import _ from 'lodash'

/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class ServicePackage extends Model {}
configure(model, {
  $fields: {
    id: {},
    name: {},
    value: {},
    category_cnh: {},
    type_service: {},
    observation: {},
    account_plan_id: {},
    company_id: {},
    deleted_at: {},
    created_at: {},
    updated_at: {}
  },
  $store: {
    name: 'servicePackageSig',
    actionName: 'register/servicePackage',
    getters: {
      totalValueSelectcredit: 'totalValueSelectcredit'
    }
  },
  $select: {
    label: 'Selecione um Pacote de Serviço',
    name: 'Serviços',
    description: ['name'] // campos para mostrar no botao do SigSelect
  },
  $grid: {
    actions: {
      edit: false
    }
  },
  $name: 'servicePackageSig'
})

export default new model()
