<template>
  <vs-popup :title="value.title" :active.sync="isOpen">
    <slot></slot>
  </vs-popup>
</template>

<script>
export default {
  components: {},
  props: ['value'],
  data () {
    return {
      show: false
    }
  },
  watch: {
    popup (value) {
      if (value) {
        const me = this
        this.$store.commit('popup/add', {
          index: this.indexSelect || 0,
          open () {
            me.isOpen = true
          },
          close () {
            me.isOpen = null
            me.active = null
          }
        })
      } else if (value === false) {
        this.$store.commit('popup/remove')
      }
    }
  },
  computed:{
    isOpen: {
      get () {
        return this.show
      },
      set (value) {
        if (value === false) {
          this.popup = false
        }
        this.show = value || false
      }
    },
    popup: {
      get () {
        return this.value.active
      },
      set (value) {
        this.value.active = value
      }
    }
  }
}
</script>