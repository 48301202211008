import axios from '@/axios.js'

export default {
  async fetchServices ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/services`)
        .then((response) => {
          commit('SET_SERVICES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  async store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/service`, payload)
        .then((response) => {
          commit('STORE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  async update ({ state }, payload) {
    return new Promise((resolve, reject) => {
      const service = state.services[payload]
      axios.put(`${process.env.VUE_APP_API_URL}/service/${service.id}`, service)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  async delete ({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const service = state.services[payload]
      axios.delete(`${process.env.VUE_APP_API_URL}/service/${service.id}`)
        .then((response) => {
          commit('DELETE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
