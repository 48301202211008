import { Model, configure } from '@/store/model/model'

/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class User extends Model {
  $hasRole (name) {
    return !!this.roles.filter(e => e.name === name).length
  }
}
configure(model, {
  $fields: {
    id: {},
    name: {},
    email: {},
    email_verified_at: {},
    password: {},
    restriction: {},
    credential: {},
    cpf: {},
    rg: {},
    orgao_emissor_rg: {},
    group: {},
    orgao_emissor_rg_uf_id: {},
    date_of_birth: {},
    gender: {},
    status_user: {},
    observation: {},
    owner: {},
    company_id: {},
    remember_token: {},
    deleted_at: {},
    created_at: {},
    updated_at: {}
  },
  $name: 'user'
})

export default new model()
