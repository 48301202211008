import _ from 'lodash'

export class Model {
  constructor (props) {
    _.assign(this, props)
    // validar
    this.$validator = {
      valid: () => {
        let resp = true
        const me = this
        // validar relacionamentos
        me.$validator.messages = []
        _.each(me.$relations, (element, key) => {
          if (element.required) {
            if (!me[key] || !me[key][0] || !me[key][0].id) {
              // vejo se selecionou pelo menos um
              me.$validator.messages.push(element.message)
              resp = false
            }
          }
        })
        // messagem
        me.$validator.message = this.$validator.messages.join(',')
        return resp
      },
      messages: []
    }

    this.$configure()
  }
  // criar copia
  $clone () {
    return new this.constructor(this.$serialize())
  }

  // remover outras propriedades antes de enviar para o servidor
  $serialize () {
    return _.pickBy(this, (value, key) => {
      return !key.startsWith('$')
    })
  }

  // preencher com relacionamentos
  $fill (state) {
    const me = this
    _.each(me.$relations, (element, key) => {
      if (!element.unset) {
        me[key] = []
        _.each(
          state[element.model.$store.name].select[me.$name].selected,
          obj => {
            if (obj) {
              me[key].push(obj.$serialize())
            }
          }
        )
        // definir id
        if (element.foreign && me[key][0]) {
          me[element.foreign] = me[key][0].id
        }
      }
    })
  }

  // configurar depois de ser instanciado
  $configure () {
    const me = this
    _.each(this.$fields, (element, key) => {
      me[key] = me[key] !== undefined ? me[key] : element.default
      // formatar valor em novo campo
      if (typeof element.format === 'function') {
        me[`${key}_formated`] = element.format(me)
      }
    })

    if (me.$relations) {
      _.each(me.$relations, (element, key) => {
        me[key] = me[key] || []
        if (me[key].length > 0) {
          // json to model
          const list = []
          _.each(me[key], obj => {
            list.push(new element.model.constructor(obj))
          })
          me[key] = list
        }
      })
    }
  }

  // title exibido no botao do SigSelect
  $title () {
    const me = this
    let title = ''
    if (me.$select && me.$select.description && me.$select.description.length) {
      me.$select.description.forEach(value => {
        if (me[value]) {
          title = `${title} ${me[value]}`
        }
      })
    }
    return title || me.$select.name
  }

  // options para o v-select
  $options () {
    return {
      label: this[this.$select.options],
      value: this.id
    }
  }

  $getOptionsByField (field) {

    const options = []

    this[field].forEach(e => {
      options.push(this.$fields[field].options.filter(a => {
        return a.value === e
      })[0])
    })

    return options
  }
}

export function configure (classe, config) {
  // configurar store
  classe.prototype.$name = config.$name

  classe.prototype.$store = {
    name: 'model',
    actionName: '/model/',
    dispatchName: 'model/',
    mutation: {
      add: 'add', // adicionar
      fetchAdd: 'setFetchGrid',
      fetchGridAdd: 'fetchGridAdd',
      delete: 'delete',
      activeForm: 'activeForm',
      select: 'select',
      selected: 'selected',
      closeSelect: 'closeSelect',
      nameSelect: 'nameSelect',
      single: 'single',
      changeSelected: 'changeSelected'
    },
    getters: {
      form: 'form',
      options: 'options',
      popup: 'popup'
    }
  }

  _.merge(config.$store, {
    commit: {
      selected: 'selected',
      select: 'select',
      clearSelected: 'clearSelected',
      changeSelected: 'changeSelected',
      single: 'single',
      delete: 'delete',
      nameSelect: 'nameSelect',
      closeSelect: 'closeSelect',
      activeForm: 'activeForm',
      activeSelectSelect: 'activeSelectSelect'
    },
    action: {
      fetchGrid: 'fetchGrid',
      saveSelected: 'bulk',
      saveSingle: '',
      delete: ''
    },
    dispatch: {
      fetchGrid: 'fetchGrid',
      saveSingle: 'saveSingle',
      saveSelected: 'saveSelected',
      delete: 'delete'
    }
  })

  _.merge(classe.prototype.$store, config.$store)

  // configuro getters
  if (classe.prototype.$store.getters) {
    const getters = {}
    _.each(classe.prototype.$store.getters, (element, key) => {
      getters[key] = `${classe.prototype.$store.name}/${element}`
    })
    classe.prototype.$store.getters = getters
  }
  // configuro commit
  const commit = {}
  _.each(classe.prototype.$store.commit, (element, key) => {
    commit[key] = `${classe.prototype.$store.name}/${element}`
  })
  classe.prototype.$store.commit = commit

  // configuro actions
  const actions = {}
  _.each(classe.prototype.$store.action, (element, key) => {
    actions[key] = `/${classe.prototype.$store.actionName}/${element}`
  })
  classe.prototype.$store.action = actions

  // configuro dispatch
  const dispatch = {}
  _.each(classe.prototype.$store.dispatch, (element, key) => {
    dispatch[key] = `${classe.prototype.$store.name}/${element}`
  })
  classe.prototype.$store.dispatch = dispatch

  // configurar grid
  classe.prototype.$grid = {
    actions: {
      delete: true,
      select: true,
      edit: true
    },
    columns: []
  }
  if (config.$grid) {
    _.merge(classe.prototype.$grid.actions, config.$grid.actions)
  }
  // configurar formulário - SigForm
  classe.prototype.$form = {
    name: 'Cadastro de Modelo'
  }
  _.merge(classe.prototype.$form, config.$form)

  // configurar validador

  // configurar fields
  _.each(config.$fields, (element, key) => {
    if (element.grid) {
      const col = {
        headerName: element.label || 'Campo',
        field: element.format ? `${key}_formated` : key,
        cellRenderer: element.cellRenderer,
        headerTooltip: element.tooltip || 'Sem observação',
        resizable: true
      }
      _.merge(col, element.columnDef)
      classe.prototype.$grid.columns.push(col)
    }
    // nome dos campos
    element.name = key
    element.label = element.label || 'Campo'
  })

  classe.prototype.$fields = config.$fields
  //classe.constructor.$fields = config.$fields
  // define relacionamentos

  classe.prototype.$relations = _.merge({}, config.$relations)

  // configurar SigSelect
  classe.prototype.$select = {
    label: 'Selecione um Modelo',
    options: 'id'
  }
  if (config.$select) {
    _.merge(classe.prototype.$select, config.$select)
  }
}

export function generateOptionsEnum (config) {


  return {
    enum: config,
    options: _.map(config, (v, k) => {
      return {
        label: v,
        value: k
      }
    })
  }
}
