import { Model, configure } from '@/store/model/model'

/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class Transaction extends Model {
  constructor (args) {
    super(args)
    this.situation_bool = this.situation === 'PAID'
  }
}

configure(model, {
  $fields: {
    account_id: {},
    account_plan_id: {},
    boleto_tax_id: {},
    company_id: {},
    contract_id: {},
    created_at: {},
    deleted_at: {},
    discount: {},
    discount_type: {},
    doctor_id: {},
    expiration_date: {},
    fee: {},
    fee_days: {},
    id: {},
    interest: {},
    interest_days: {},
    number_parcel: {},
    observation: {},
    parcel: {},
    payday: {},
    receivable: {},
    service_id: {},
    servicePackages: {},
    situation: {
      default: 'PENDING'
    },
    student_id: {},
    transaction_id_origin: {},
    transaction_origin_fee_id: {},
    type: {},
    type_fee: {},
    type_interest: {},
    type_payment_id: {},
    updated_at: {},
    user_id: {},
    value_parcel: {},
    value_pay: {
      default: 0
    },
    value_total: {},
    vehicle_maintenance_id: {}
  },
  $store: {
    name: 'transactionSig',
    actionName: 'cash/transaction',
    getters: {
      selectedcredit: 'selectedcredit'
    }
  },
  $name: 'transaction'
})

export default new model
