import createState from '@/store/model/state'

const state =  createState()

state.select.typePayment = {
  disabled: false,
  isOpen: false,
  selected: [],
  popup: false
}
export default state