export default {
  forSelect (state) {
    const servicePackages = []
    for (const k in state.servicePackages) {
      delete state.servicePackages[k].pivot
      servicePackages.push({
        value: state.servicePackages[k].id,
        label: `${state.servicePackages[k].name} ${(state.servicePackages[k].category_cnh || '')}`,
        currencyValue: state.servicePackages[k].value,
        data: state.servicePackages[k]
      })
    }
    /**
     * !!
     * IMPORTANTE PARA PEGAR A QUANTIDADE DE ÍTENS E SALVAR ESTE DADO NAS TRANSAÇÕES
     * !!
     */
    servicePackages.map(sp => {
      if (sp.data.services) sp.data.services.map(s => { s.quantity = s.pivot.quantity })
      if (sp.data.type_service && sp.data.type_service.toUpperCase() === 'AULA AVULSA') { sp.label = `${sp.data.name} x ${sp.data.quantity}` }
    })
    return servicePackages
  }
}
