import { Model, configure, generateOptionsEnum } from '@/store/model/model'

const balanceType = generateOptionsEnum({
  DAILY: 'Dia'
}, {
  WEEKLY: 'Semana'
}, {
  BIWEEKLY: 'Quinzena'
}, {
  MONTHLY: 'MÊS'
}, {
  QUARTERLY: 'Trimestre'
}, {
  'SEMI-ANNUAL': 'Semestre'
}, {
  ANNUAL: 'Ano'
})

/**
 * Lembre de registrar o store em index.js
 */

const model = class ConfigSchedule extends Model {
  constructor (args) {
    super(args)
  }

  $getComponent (field) {
    switch (field.type) {
    default:
      return 'vs-input'
    }
  }
}

configure(model, {
  $fields: {
    id: {},
    block_debit: {
      label: 'Impedir Aluno Inadimplente',
      default: 1
    },
    notify_debit: {
      label: 'Notificar Aluno Inadimplente',
      default: 1
    },
    double_class: {
      label: 'Agendar aulas consecutivas (de 100 minutos)',
      default: 0
    },
    fine_practical_class: {
      default: 0
    },
    fine_theoretical_class: {
      default: 0
    },
    company_id: {},
    created_at: {},
    updated_at: {}
  },
  $store: {
    name: 'configSchedule',
    actionName: 'schedule/configSchedule',
    actions: {
      single: 'single'
    },
    dispatch: {
      single: 'single'
    }
  },
  $name: 'configSchedule'
})

export default new model()
