import createActions from '@/store/model/actions'
import axios from '@/axios.js'
import model from './model'

const actions =  createActions(model)

actions.single = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.VUE_APP_API_URL}${model.$store.action.fetchGrid}`)
      .then((response) => {
        // data.data é a lista de registros retornados
        // data.total é o número da quantidade de registros
        commit(model.$store.mutation.single, new model.constructor(response.data.data[0]))
        resolve(response)
      })
      .catch((error) => { reject(error) })
  })
}

export default actions
