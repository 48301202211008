/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js'


import router from '@/router'

export default {
  // JWT
  loginJWT ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {

          // If there's user data in response
          if (response.data.userData) {

            // Set accessToken
            localStorage.setItem('accessToken', response.data.accessToken)

            // Update user details
            commit('UPDATE_USER_INFO', response.data.userData, {root: true})

            // Set bearer token in axios
            commit('SET_BEARER', response.data.accessToken)

            // Navigate User to homepage
            // router.push(router.currentRoute.query.to || '/')

            // Temporáriamente direcionando para Alunos
            router.push(router.currentRoute.query.to || '/student/list')

            resolve(response)
          } else {
            reject({message: 'Credenciais inválidas'})
          }

        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data === 'UNAUTHORIZED_AT_THIS_TIME') {
            reject({
              message: 'Acesso não autorizado neste horário!'
            })
          } else {
            reject({
              message: 'Credenciais inválidas!'
            })
          }
        })
    })
  },
  registerUserJWT ({ commit }, payload) {

    const { displayName, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve, reject) => {

      // Check confirm password
      if (password !== confirmPassword) {
        reject({message: 'Password doesn\'t match. Please try again.'})
      }

      jwt.registerUser(displayName, email, password)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/')

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken)
          commit('UPDATE_USER_INFO', response.data.userData, {root: true})

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  fetchAccessToken ({ commit }) {
    return new Promise((resolve, reject) => {
      jwt.refreshToken()
        .then(response => {
          // If there's user data in response
          if (response.data.userData) {

            // Set accessToken
            localStorage.setItem('accessToken', response.data.accessToken)

            // Update user details
            // commit('UPDATE_USER_INFO', response.data.userData, {root: true})

            // Set bearer token in axios não sei sei isso resolve. Estou usando um Request interceptor
            commit('SET_BEARER', response.data.accessToken)

            // Navigate User to homepage
            // router.push(router.currentRoute.query.to || '/')

            resolve(response)
          } else {
            reject({message: 'Credenciais inválidas'})
          }

        })
        .catch(error => {
          reject({
            error
          })
        })
    })
  },
  logout () {
    return new Promise((resolve) => {
      jwt.logout().then(response => { resolve(response) })
    })
  }
}
