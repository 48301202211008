import _ from 'lodash'

export default function (model) {
  return {
    add (state, payload) {
      state.list = payload
    },
    setFetchGrid (state, payload) {
      state.fetchGrid = payload
      payload.data.forEach(element => {
        state.options.push(element.$options())        
      })
    },
    fetchGridAdd (state, payload) { // depois de adicionar novo registro
      state.fetchGrid.data.push(payload)
      state.fetchGrid.total++ 
    },
    activeForm (state, payload) { // abrir/fechar formuario para novo registro
      _.merge(state.form, payload)
    },
    selected (state, payload) { // ao criar modelos relacionados: hasOne, hasMany
      if (payload.data) {
        state.select[state.select.name].selected[state.select.index || 0] = payload.data.$clone()
      } else {
        state.select[state.select.name].selected[state.select.index || 0] = new model.constructor()
      }
    },
    closeSelect (state, payload) { // ao criar modelos relacionados: hasOne, hasMany
      _.merge(state.select[state.select.name], payload)
    },
    select (state, payload) { // ao criar modelos relacionados: hasOne, hasMany
      if (Number.isInteger(payload.index)) {
        state.select.index = payload.index
      }
      if (payload && payload.name) {
        if (payload.isOpen !== undefined) {
          state.select[payload.name].isOpen = payload.isOpen
        }
        if (payload.disabled !== undefined) {
          state.select[payload.name].disabled = payload.disabled
        }
      }
    },
    nameSelect (state, payload) { // ao criar modelos relacionados: hasOne, hasMany
      state.select.name = payload
    },
    changeSelected (state, payload) { // ao criar modelos relacionados: hasOne, hasMany

      if (payload.name) {
        state.select.name = payload.name
      } else if (!state.select.name) { //evita erros
        return
      }

      state.select[state.select.name].selected = []         

      if (Array.isArray(payload)) {
        const selected = []
        _.each(payload, element => {
          selected.push(new model.constructor(element))
        })
        state.select[state.select.name].selected = selected
      } else  if (Array.isArray(payload.data) && payload.data.length > 0) {
        payload.data.forEach(element => {
          state.select[state.select.name].selected.push(new model.constructor(element))
        })
      } else if (payload.data) {
        state.select[state.select.name].selected.push(new model.constructor(payload.data))
      }
    },
    clearSelected (state) {
      state.selected = []
    },
    single (state, payload) { // ao criar um novo registro do modelo
      state.single = payload ? payload.$clone() : new model.constructor()
      
    },
    activeSelect (state, payload) {
      state.isActiveSelect = payload
    },
    localDelete (state) {
      if (state.selectDelete.id) {
        state.fetchGrid.data = state.fetchGrid.data.map((e) => {
          if (e.id !== state.selectDelete.id) return e
        })
      }
      state.fetchGrid.total--
    },
    delete (state, payload) {
      state.selectDelete = payload
    }
  }
   
} 