var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("label", { staticClass: "vs-input--label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c("vs-input-number", {
        staticClass: "mt-2",
        attrs: { min: "1", max: _vm.max },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }