var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm.text
        ? _c("feather-icon", {
            attrs: {
              icon: "HelpCircleIcon",
              svgClasses: "h-3 hover:text-primary cursor-pointer",
            },
            on: { click: _vm.showInfo },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }