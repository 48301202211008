
export default {
  //MANIPULA A ÚNICA CONTA OBJETO FORA DO ARRAY DE CONTAS NO ESTADO
  SET (state, payload) {
    state.account = Object.assign({}, state.account, payload) //precisa ser assim para manter a reatividade
  },

  //ADICIONA UMA NOVA CONTA NO ARRAY DE CONTAS
  ADD (state, payload) {
    state.accounts.push(payload) //precisa ser assim para manter a reatividade
  },

  //SUBSTITUI O ARRAY DE CONTAS POR UM NOVO
  REFRESH (state, payload) {
    // CONTAS INTERNAS
    state.accounts.data = payload.data.filter(a => !a.external)
    state.accounts.total = payload.data.filter(a => !a.external).length

    // CONTAS EXTERNAS
    state.externalAccounts.data = payload.data.filter(a => a.external)
    state.externalAccounts.total = payload.data.filter(a => a.external).length
  },
  REFRESH_SELECT (state, payload) {
    state.accountsSelect = payload
  },

  //EXCLUI UMA CONTA DO ARRAY DE CONTAS
  DELETE (state, payload) {
    const cnhIndex = state.accounts.data.findIndex(o => o.id === payload)
    state.accounts.data.splice(cnhIndex, 1) //precisa ser assim para manter a reatividade
    state.accounts.total = state.accounts.total - 1 //precisa ser assim para manter a reatividade
    if (state.account && state.account.id === payload) {
      state.account = Object.assign({}, {}) //precisa ser assim para manter a reatividade
    }
  }
}
