import Vue from 'vue'

export default {
  SET (state, payload) {
    state.accountPlan = Object.assign({}, state.accountPlan, payload) //precisa ser assim para manter a reatividade
  },

  ADD (state, payload) {
    state.accountPlans.push(payload) //precisa ser assim para manter a reatividade
  },

  //SUBSTITUI O ARRAY DE CONTAS POR UM NOVO
  REFRESH (state, payload) {
    state.accountPlans = payload
  },

  UPDATE (state, payload) {
    const index = state.accountPlans.findIndex((o) => o.id === payload.id)
    Vue.set(state.accountPlans, index, payload)
  },

  DELETE (state, payload) {
    const index = state.accountPlans.findIndex((o) => o.id === payload)
    state.accountPlans.splice(index, 1) //precisa ser assim para manter a reatividade
  }
}
