import { Model, configure } from '@/store/model/model'

/**
 * Lembre de registrar o store em index.js
 */

const model = class CashFlow extends Model {
  constructor (args) {
    super(args)
  }
}

configure(model, {
  $fields: {
    id: {},
    expiration_date: {
      label: 'Vencimento'
    },
    value_parcel: {
      label: 'Valor'
    },
    value: {
      label: 'Total'
    },
    type: {
      label: 'Tipo'
    },
    direction: {
      label: 'Tipo'
    },
    observation: {
      label: 'Observação'
    },
    transaction_id: {},
    account_plan_id: {},
    account_id: {},
    cashier_id: {},
    type_payment_id: {},
    service_id: {},
    student_id: {},
    doctor_id: {},
    user_id: {},
    company_id: {},
    deleted_at: {},
    created_at: {},
    updated_at: {}
  },
  $store: {
    name: 'cashFlowSig',
    actionName: 'financial/cashier',
    action: {
      single: 'single',
      closure: 'closure'
    },
    dispatch: {
      single: 'single',
      closure: 'closure'
    },
    getters: {
      closure: 'closure'
    },
    mutation: {
      closure: 'closure'
    }
  },
  $name: 'configCashier'
})

export default new model()
