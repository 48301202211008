import { Model, configure } from '@/store/model/model'
import account from '../account/model'
import typeDownPay from '../typeDownPay/model'


/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class FinancialCard extends Model {
  constructor (args) {
    super(args)
    this.auto_down = this.type_down_pay_id 
  }
}

configure(model, {
  $fields: {
    id: {},
    name: {
      label: 'Nome',
      grid: true
    },
    interest: {
      label: 'Taxa'
    },
    type_interest: {
      label: 'Tipo Taxa',
      default: '%',
      options: [
        {
          label: 'NUMÉRICO',
          value: '$'
        }, {
          label: 'PERCENTUAL',
          value: '%'
        }
      ]
    },
    interest_comsumer: {
      label: 'Cliente Paga Taxa'
    },
    order: {},
    type_down_pay_id: {},
    account_id: {},
    company_id: {},
    deleted_at: {},
    created_at: {},
    updated_at: {},
    // esses abaixo so existem no vue
    auto_down:{
      label: 'Quitar Automáticamente'
    }
  },
  $relations: {
    account: {
      model: account,
      required: true,
      foreign: 'account_id',
      message: 'Selecione uma Conta'
    },
    type_down_pay: {
      model: typeDownPay,
      foreign: 'type_down_pay_id'
    }
  },
  $store: {
    name: 'financialCard',
    actionName: 'financial/financialCard'
  },
  $select: {
    label: 'Selecione o POS (Cartão)',
    name: 'POS',
    description: ['name']
  },
  $form: {
    name: 'Cadastro de Maquina de Cartão'
  },
  $name: 'pos'
})

export default new model
