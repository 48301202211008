import axios from '@/axios.js'

export default {
  fetchAll ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/servicePackages`, payload)
        .then((response) => {
          commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  //NO FILTER SEMPRE DEVO FAZER A CONSULTA A API PARA RENOVAR O ESTADO POR COMPLETO
  fetchFilter ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/servicePackages/filter`, payload)
        .then((response) => {
          commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/servicePackage`, payload)
        .then((response) => {
          commit('SET', response.data) // seto o objeto
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/servicePackage/${payload.id}`, payload)
        .then((response) => {
          commit('UPDATE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  delete ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/servicePackage/${id}`)
        .then((response) => {
          commit('DELETE', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
