import studentSig from './student'
import group from './group'
import observationStudent from './observationStudent'
import studentDocument from './studentDocument'
import debitStudent from './debitStudent'
import employeeSig from './employee'
import servicePackageSig from './servicePackage'

export default {
  studentSig,
  group,
  servicePackageSig,
  employeeSig,
  observationStudent,
  studentDocument,
  debitStudent
}
