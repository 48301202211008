export default {
  accountsSelect: [], //para os selects
  accounts: {
    data: [], //para o grid
    total: 0
  },
  account: {},
  externalAccounts: {
    data: [], //para o grid
    total: 0
  }
}
