<template>
  <div>
    <slot></slot>
    <feather-icon
      v-if="text"
      icon="HelpCircleIcon"
      svgClasses="h-3 hover:text-primary cursor-pointer"
      @click="showInfo"
    />
  </div>
</template>

<script>
export default {
  props: {
    text: {},
    title: {
      default: 'Informação de ajuda'
    }
  },
  methods: {
    showInfo () {
      this.$vs.notify({
        title: this.title,
        text: this.text,
        position: 'top-center',
        time: 6000
      })
    }
  }
}
</script>
