function state () {
  return {
    fetchGrid: {
      data: [],
      total: 0
    },
    options: [],
    single: {},
    selectDelete: -1,
    form: {
      isOpen: false,
      popup: false
    },
    select: {
      name: null
    },
    closeSelected: false
  }
}

export default state