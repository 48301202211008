import createState from '@/store/model/state'

const state =  createState()

state.select.credit = {
  disabled: false,
  isOpen: false,
  popup: false,
  selected: []
}
export default state