export default {
  forSelect (state) {
    const typePayments = []
    for (const k in state.typePayments) {
      typePayments.push({
        value: state.typePayments[k].id,
        label: state.typePayments[k].type_payment
      })
    }
    return typePayments
  }
}
