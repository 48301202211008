var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-prompt",
    {
      attrs: {
        title: _vm.title,
        active: _vm.active,
        "accept-text": "Confirmar",
        "cancel-text": "Cancelar",
      },
      on: {
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
        accept: function ($event) {
          return _vm.$emit("accept")
        },
        close: function ($event) {
          return _vm.$emit("close")
        },
        "update:active": function ($event) {
          return _vm.$emit("update:active", $event)
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }