import Vue from 'vue'

export default {
  SET (state, payload) {
    state.servicePackage = Object.assign({}, state.servicePackage, payload) //precisa ser assim para manter a reatividade
  },

  ADD (state, payload) {
    state.servicePackages.data.push(payload) //precisa ser assim para manter a reatividade
    state.servicePackages.total = state.servicePackages.total + 1 //precisa ser assim para manter a reatividade
  },

  //SUBSTITUI O ARRAY DE CONTAS POR UM NOVO
  REFRESH (state, payload) {
    state.servicePackages = payload
  },

  //ALTERA UM OBJETO DO ARRAY NO ESTADO pelo ID
  UPDATE (state, payload) {
    const index = state.servicePackages.data.findIndex(o => o.id === payload.id)
    // state.servicePackages.data[index] = Object.assign({}, state.servicePackages.data[index], payload) //precisa ser assim para manter a reatividade
    Vue.set(state.servicePackages.data, index, payload)

    // const index = state.servicePackages.data.findIndex(o => o.id === payload.id)
    // state.servicePackages.data[index] = Object.assign({}, state.servicePackages.data[index], payload) //precisa ser assim para manter a reatividade
  },


  //EXCLUI UMA CONTA DO ARRAY DE CONTAS
  DELETE (state, payload) {
    const index = state.servicePackages.data.findIndex(o => o.id === payload)
    state.servicePackages.data.splice(index, 1) //precisa ser assim para manter a reatividade
    state.servicePackages.total = state.servicePackages.total - 1 //precisa ser assim para manter a reatividade
  }
}
