import axios from '@/axios.js'

const actions = {

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth ({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },

  // VxAutoSuggest
  updateStarredPage ({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  // The Navbar
  arrangeStarredPagesLimited ({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore ({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay ({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme ({ state, getters, commit }, val) {
    commit('UPDATE_THEME', val)

    const conf = getters.companyConfigurations.theme_configuration
    commit('UPDATE_PRIMARY_COLOR', conf && conf.colors ? conf.colors.primary : '#158482')
    commit('UPDATE_MAIN_LAYOUT_TYPE', conf && conf.mainLayoutType ? conf.mainLayoutType : 'vertical')
    commit('TOGGLE_REDUCE_BUTTON', conf && conf.sidebarCollapsed ? conf.sidebarCollapsed : false)

    const colors = {
      primary  : conf && conf.colors ? conf.colors.primary   : '#158482', // #7367F0 (default) | 158482
      success  : conf && conf.colors ? conf.colors.success   : '#28C76F',
      danger   : conf && conf.colors ? conf.colors.danger    : '#EA5455',
      warning  : conf && conf.colors ? conf.colors.warning   : '#FF9F43',
      dark     : conf && conf.colors ? conf.colors.dark      : '#1E1E1E',
      secondary: conf && conf.colors ? conf.colors.secondary : '#5c636a'
    }

    // CONFIGS
    const theme_configuration = {
      disableCustomizer : false,        // options[Boolean] : true, false(default)
      disableThemeTour  : true,         // options[Boolean] : true, false(default)
      footerType        : 'static',     // options[String]  : static(default) / sticky / hidden
      hideScrollToTop   : false,        // options[Boolean] : true, false(default)
      mainLayoutType    : conf && conf.mainLayoutType ? conf.mainLayoutType : 'vertical',   // options[String]  : vertical(default) / horizontal
      navbarColor       : '#fff',       // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
      navbarType        : 'floating',   // options[String]  : floating(default) / static / sticky / hidden
      routerTransition  : 'zoom-fade',  // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
      rtl               : false,        // options[Boolean] : true, false(default)
      sidebarCollapsed  : conf && conf.sidebarCollapsed ? conf.sidebarCollapsed : false,        // options[Boolean] : true, false(default)
      theme             : val,          // options[String]  : "light"(default), "dark", "semi-dark"
      colors
    }

    const company = JSON.parse(JSON.stringify(state.AppActiveUser.company))
    if (!company.configuration) company.configuration = {}
    company.configuration.theme_configuration = JSON.parse(JSON.stringify(theme_configuration))
    commit('UPDATE_USER_INFO', { company })

    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/configuration`, {
        type_configuration: 'BY_COMPANY',
        theme_configuration
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updatePrimaryColor ({ state, getters, commit }, val) {
    commit('UPDATE_PRIMARY_COLOR', val)

    const conf = getters.companyConfigurations.theme_configuration

    const colors = {
      primary  : val, // '#158482', // #7367F0 (default) | 158482
      success  : conf && conf.colors ? conf.colors.success   : '#28C76F',
      danger   : conf && conf.colors ? conf.colors.danger    : '#EA5455',
      warning  : conf && conf.colors ? conf.colors.warning   : '#FF9F43',
      dark     : conf && conf.colors ? conf.colors.dark      : '#1E1E1E',
      secondary: conf && conf.colors ? conf.colors.secondary : '#5c636a'
    }

    // CONFIGS
    const theme_configuration = {
      disableCustomizer : false,        // options[Boolean] : true, false(default)
      disableThemeTour  : true,         // options[Boolean] : true, false(default)
      footerType        : 'static',     // options[String]  : static(default) / sticky / hidden
      hideScrollToTop   : false,        // options[Boolean] : true, false(default)
      mainLayoutType    : conf && conf.mainLayoutType ? conf.mainLayoutType : 'vertical',   // options[String]  : vertical(default) / horizontal
      navbarColor       : '#fff',       // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
      navbarType        : 'floating',   // options[String]  : floating(default) / static / sticky / hidden
      routerTransition  : 'zoom-fade',  // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
      rtl               : false,        // options[Boolean] : true, false(default)
      sidebarCollapsed  : conf && conf.sidebarCollapsed ? conf.sidebarCollapsed : false,        // options[Boolean] : true, false(default)
      theme             : conf && conf.theme ? conf.theme   : 'light',          // options[String]  : "light"(default), "dark", "semi-dark"
      colors
    }

    const company = JSON.parse(JSON.stringify(state.AppActiveUser.company))
    if (!company.configuration) company.configuration = {}
    company.configuration.theme_configuration = JSON.parse(JSON.stringify(theme_configuration))
    commit('UPDATE_USER_INFO', { company })

    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/configuration`, {
        type_configuration: 'BY_COMPANY',
        theme_configuration
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateMainLayoutType ({ state, getters, commit }, val) {
    commit('UPDATE_MAIN_LAYOUT_TYPE', val)

    const conf = getters.companyConfigurations.theme_configuration
    const colors = {
      primary  : conf && conf.colors ? conf.colors.primary   : '#158482', // #7367F0 (default) | 158482
      success  : conf && conf.colors ? conf.colors.success   : '#28C76F',
      danger   : conf && conf.colors ? conf.colors.danger    : '#EA5455',
      warning  : conf && conf.colors ? conf.colors.warning   : '#FF9F43',
      dark     : conf && conf.colors ? conf.colors.dark      : '#1E1E1E',
      secondary: conf && conf.colors ? conf.colors.secondary : '#5c636a'
    }

    // CONFIGS
    const theme_configuration = {
      disableCustomizer : false,        // options[Boolean] : true, false(default)
      disableThemeTour  : true,         // options[Boolean] : true, false(default)
      footerType        : 'static',     // options[String]  : static(default) / sticky / hidden
      hideScrollToTop   : false,        // options[Boolean] : true, false(default)
      mainLayoutType    : val,          // options[String]  : vertical(default) / horizontal
      navbarColor       : '#fff',       // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
      navbarType        : 'floating',   // options[String]  : floating(default) / static / sticky / hidden
      routerTransition  : 'zoom-fade',  // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
      rtl               : false,        // options[Boolean] : true, false(default)
      sidebarCollapsed  : conf && conf.sidebarCollapsed ? conf.sidebarCollapsed : false,        // options[Boolean] : true, false(default)
      theme             : conf && conf.theme ? conf.theme   : 'light',          // options[String]  : "light"(default), "dark", "semi-dark"
      colors
    }

    const company = JSON.parse(JSON.stringify(state.AppActiveUser.company))
    if (!company.configuration) company.configuration = {}
    company.configuration.theme_configuration = JSON.parse(JSON.stringify(theme_configuration))
    commit('UPDATE_USER_INFO', { company })

    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/configuration`, {
        type_configuration: 'BY_COMPANY',
        theme_configuration
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  toggleReduceButton ({ state, getters, commit }, val) {
    commit('TOGGLE_REDUCE_BUTTON', val)

    const conf = getters.companyConfigurations.theme_configuration

    const colors = {
      primary  : conf && conf.colors ? conf.colors.primary   : '#158482', // #7367F0 (default) | 158482
      success  : conf && conf.colors ? conf.colors.success   : '#28C76F',
      danger   : conf && conf.colors ? conf.colors.danger    : '#EA5455',
      warning  : conf && conf.colors ? conf.colors.warning   : '#FF9F43',
      dark     : conf && conf.colors ? conf.colors.dark      : '#1E1E1E',
      secondary: conf && conf.colors ? conf.colors.secondary : '#5c636a'
    }

    // CONFIGS
    const theme_configuration = {
      disableCustomizer : false,        // options[Boolean] : true, false(default)
      disableThemeTour  : true,         // options[Boolean] : true, false(default)
      footerType        : 'static',     // options[String]  : static(default) / sticky / hidden
      hideScrollToTop   : false,        // options[Boolean] : true, false(default)
      mainLayoutType    : conf && conf.mainLayoutType ? conf.mainLayoutType : 'vertical',   // options[String]  : vertical(default) / horizontal
      navbarColor       : '#fff',       // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
      navbarType        : 'floating',   // options[String]  : floating(default) / static / sticky / hidden
      routerTransition  : 'zoom-fade',  // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
      rtl               : false,        // options[Boolean] : true, false(default)
      sidebarCollapsed  : val,        // options[Boolean] : true, false(default)
      theme             : conf && conf.theme ? conf.theme   : 'light',          // options[String]  : "light"(default), "dark", "semi-dark"
      colors
    }

    const company = JSON.parse(JSON.stringify(state.AppActiveUser.company))
    if (!company.configuration) company.configuration = {}
    company.configuration.theme_configuration = JSON.parse(JSON.stringify(theme_configuration))
    commit('UPDATE_USER_INFO', { company })

    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/configuration`, {
        type_configuration: 'BY_COMPANY',
        theme_configuration
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo ({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload)
  },
  updateUserRole ({ dispatch }, payload) {
    // Change client side
    payload.aclChangeRole(payload.userRole)

    // Make API call to server for changing role

    // Change userInfo in localStorage and store
    dispatch('updateUserInfo', {userRole: payload.userRole})
  }
}

export default actions
