<template>
  <vs-input
    class="w-full"
    ref="input"
    :label="label"
    @change="changeValue"
    v-model="inputValue"
    v-currency="currencyConfig"
    :disabled="disabled"
  />
</template>

<script>
import {
  setValue,
  getValue,
  CurrencyDirective
} from 'vue-currency-input'

export default {
  props: {
    value: {},
    label: {},
    disabled: {},
    validate: {}
  },
  data () {
    return {
      inputValue: this.mixinCurrency(this.value),
      currencyConfig: {
        currency: { prefix: 'R$ ' },
        valueAsInteger: false,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      }
    }
  },
  watch:{
    value (value) {
      setValue(this.$refs.input, value)
    }
  },
  directives: { currency: CurrencyDirective },
  methods: {
    changeValue () {
      this.$emit('input', getValue(this.$refs.input))
    }
  }
}
</script>
