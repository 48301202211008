import accountPlan from './account-plan'
import account from './account'
import servicePackage from './service-package'
import service from './services'
import userManagement from './user-management'
import financial from './financial'
import cashier from './cashier'
import report from './report'
import schedule from './schedule'
import register from './register'
import httpCommand from './httpCommand'
import popup from './popup'
import typePayment1 from '@/store/type-payment/moduleTypePayment.js'
import account1 from '@/store/account/moduleAccount.js'
import servicePackage1 from '@/store/service-package/moduleServicePackage.js'
import accountPlan1 from '@/store/account-plan/moduleAccountPlan.js'


export default {
  ...schedule,
  ...report,
  ...register,
  ...cashier,
  ...financial,
  userManagement,
  accountPlan,
  account,
  servicePackage,
  service,
  httpCommand,
  popup,
  typePayment1,
  account1,
  accountPlan1,
  servicePackage1
}
