
export default {
  SET (state, payload) {
    state.typePayment = Object.assign({}, state.typePayment, payload) //precisa ser assim para manter a reatividade
  },
  ADD (state, payload) {
    state.typePayments.push(...payload) //precisa ser assim para manter a reatividade
  },

  //SUBSTITUI O ARRAY DE CONTAS POR UM NOVO
  REFRESH (state, payload) {
    state.typePayments = payload
  },

  DELETE (state, payload) {
    // const cnhIndex = state.typePayment.cnhs.findIndex((cnh) => cnh.id === payload)
    // state.student.cnhs.splice(cnhIndex, 1) //precisa ser assim para manter a reatividade
    console.log(payload)
  }
}
