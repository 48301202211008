import createState from '@/store/model/state'

const state =  createState()

state.select.typePayment = {
  disabled: false,
  isOpen: false,
  popup: false,
  selected: []
}
state.select.specie = {
  disabled: false,
  isOpen: false,
  popup: false,
  selected: []
}
export default state