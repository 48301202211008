import { Model, configure } from '@/store/model/model'
import _ from 'lodash'
import moment from 'moment'
/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class ObservationStudent extends Model {}

configure(model, {
  $fields: {
    id: {},
    created_at: {
      label: 'Data',
      grid: true,
      format (e) {

        return e.created_at ? moment(e.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
      }
    },
    observation: {
      label: 'Descrição',
      grid: true,
      columnDef: {
        autoHeight: true,
        wrapText: true,
        cellStyle: {'white-space': 'normal'}
      }
    },
    user_id: {
      label: 'Criador',
      grid: true,
      format (e) {
        return e.user ? e.user.name : ''
      }
    },
    deleted_at: {},
    updated_at: {}
  },
  $store: {
    name: 'observationStudent',
    actionName: 'students/observation',
    actions: {
      fetchStudent: 'fetchStudent'
    },
    dispatch: {
      fetchStudent: 'fetchStudent'
    }
  },
  $name: 'observationStudent'
})

export default new model()
