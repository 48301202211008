import { Model, configure } from '@/store/model/model'
/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class Credit extends Model {}

configure(model, {
  $store: {
    name: 'credit',
    actionName: 'cashier/credit'
  },
  $form: {
    name: 'Cadastro de Receita'
  },
  $name: 'credit'
})

export default new model()
