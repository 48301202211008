export default {
  forSelect (state) {
    const accounts = []
    for (const k in state.accountsSelect) {
      accounts.push({
        value: state.accountsSelect[k].id,
        label: state.accountsSelect[k].account,
        type_payments: state.accountsSelect[k].type_payments,
        card_account: state.accountsSelect[k].card_account
      })
    }
    return accounts
  }
}
