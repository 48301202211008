/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"
import moment from 'moment'
const getters = {

  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {

    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return 'xl'
    else if (state.windowWidth >= 992) return 'lg'
    else if (state.windowWidth >= 768) return 'md'
    else if (state.windowWidth >= 576) return 'sm'
    else return 'xs'
  },

  scrollbarTag: state => {
    return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
  },

  companyConfigurations: state => {
    let companyConfiguration = JSON.parse(JSON.stringify(state.AppActiveUser.company.configuration))
    const companyReceiptNumberControl = JSON.parse(JSON.stringify(state.AppActiveUser.company.receipt_number_control))

    if (!companyConfiguration) companyConfiguration = {}
    if (!companyConfiguration.financial) companyConfiguration.financial = { cashier: false }
    if (!companyConfiguration.general) companyConfiguration.general = { uppercase_forms: false }
    if (!companyConfiguration.reporting_footer) companyConfiguration.reporting_footer = { practicalClass: '' }
    if (!companyConfiguration.documents) companyConfiguration.documents = {
      receipt_vias: 2,
      receipt_observations:false
    }

    if (!companyReceiptNumberControl) {
      Object.assign(companyConfiguration, {receipt_number_control: { last_number: 0 }})
    } else {
      Object.assign(companyConfiguration, {receipt_number_control: companyReceiptNumberControl})
    }
    return companyConfiguration
  },

  scheduleClassTimes: (state, getters) => employee => {
    // HORÁRIO DEFAULT
    // const h = [
    //   ['06:00 06:50', '06:50 07:40', '07:40 08:30', '08:30 09:20', '09:20 10:10', '10:10 11:00', '11:00 11:50'],
    //   ['13:00 13:50', '13:50 14:40', '14:40 15:30', '15:30 16:20', '16:20 17:10', '17:10 18:00', '18:00 18:50'],
    //   ['19:40 20:30', '20:30 21:20', '22:10 23:00', '23:00 23:50']
    // ]

    // A duração é definida em nível de empresa e nao de user ou employee
    const duration = state.AppActiveUser.company.configuration && state.AppActiveUser.company.configuration.schedule && state.AppActiveUser.company.configuration.schedule.duration ? state.AppActiveUser.company.configuration.schedule.duration : 50

    // Caso esteja utilizando horários específicos e diferentes para cada employee
    if (employee && employee.config && getters.companyConfigurations && getters.companyConfigurations.schedule && getters.companyConfigurations.schedule.timesForCompany === false) {
      // uso o horário do empregado
      const intervals = employee.config.schedule.intervals ? employee.config.schedule.intervals : []
      // MATUTINO
      const timesMatutino =  []
      const periodMatutinoStart = (employee.config.schedule.period && employee.config.schedule.period.matutino.start) ? employee.config.schedule.period.matutino.start : '06:00'
      const periodMatutinoEnd = (employee.config.schedule.period && employee.config.schedule.period.matutino.end) ? employee.config.schedule.period.matutino.end : '11:50'

      let beginningTimeMatutino = moment(periodMatutinoStart, 'HH:mm')
      const endTimeMatutino     = moment(periodMatutinoEnd, 'HH:mm')
      do {
        timesMatutino.push(`${beginningTimeMatutino.format('HH:mm')} ${moment(beginningTimeMatutino, 'HH:mm').add(duration, 'm').format('HH:mm')}`)
        const index = intervals.findIndex((o) => o.afterBlock === timesMatutino[timesMatutino.length - 1])
        if (index >= 0) {
          //existe um intervalo após este bloco, devo adicionar o intervalo para somar ao início do próx período
          beginningTimeMatutino = moment(moment(beginningTimeMatutino, 'HH:mm').add(parseInt(duration) + parseInt(moment.duration(intervals[index].time, 'HH:mm').asMinutes()), 'm'))
        } else {
          beginningTimeMatutino = moment(moment(beginningTimeMatutino, 'HH:mm').add(duration, 'm'))
        }
      }
      while (beginningTimeMatutino.isBefore(endTimeMatutino))

      // VESPERTINO
      const timesVespertino =  []
      const periodVespertinoStart = (employee.config.schedule.period && employee.config.schedule.period.vespertino.start) ? employee.config.schedule.period.vespertino.start : '13:00'
      const periodVespertinoEnd = (employee.config.schedule.period && employee.config.schedule.period.vespertino.end) ? employee.config.schedule.period.vespertino.end : '18:50'

      let beginningTimeVespertino = moment(periodVespertinoStart, 'HH:mm')
      const endTimeVespertino     = moment(periodVespertinoEnd, 'HH:mm')
      do {
        timesVespertino.push(`${beginningTimeVespertino.format('HH:mm')} ${moment(beginningTimeVespertino, 'HH:mm').add(duration, 'm').format('HH:mm')}`)
        const index = intervals.findIndex((o) => o.afterBlock === timesVespertino[timesVespertino.length - 1])
        if (index >= 0) {
          //existe um intervalo após este bloco, devo adicionar o intervalo para somar ao início do próx período
          beginningTimeVespertino = moment(moment(beginningTimeVespertino, 'HH:mm').add(parseInt(duration) + parseInt(moment.duration(intervals[index].time, 'HH:mm').asMinutes()), 'm'))
        } else {
          beginningTimeVespertino = moment(moment(beginningTimeVespertino, 'HH:mm').add(duration, 'm'))
        }
      }
      while (beginningTimeVespertino.isBefore(endTimeVespertino))

      // NOTURNO
      const timesNoturno =  []
      const periodNoturnoStart = (employee.config.schedule.period && employee.config.schedule.period.noturno.start) ? employee.config.schedule.period.noturno.start : '19:40'
      const periodNoturnoEnd = (employee.config.schedule.period && employee.config.schedule.period.noturno.end) ? employee.config.schedule.period.noturno.end : '23:50'

      let beginningTimeNoturno = moment(periodNoturnoStart, 'HH:mm')
      const endTimeNoturno     = moment(periodNoturnoEnd, 'HH:mm')
      do {
        timesNoturno.push(`${beginningTimeNoturno.format('HH:mm')} ${moment(beginningTimeNoturno, 'HH:mm').add(duration, 'm').format('HH:mm')}`)
        const index = intervals.findIndex((o) => o.afterBlock === timesNoturno[timesNoturno.length - 1])
        if (index >= 0) {
          //existe um intervalo após este bloco, devo adicionar o intervalo para somar ao início do próx período
          beginningTimeNoturno = moment(moment(beginningTimeNoturno, 'HH:mm').add(parseInt(duration) + parseInt(moment.duration(intervals[index].time, 'HH:mm').asMinutes()), 'm'))
        } else {
          beginningTimeNoturno = moment(moment(beginningTimeNoturno, 'HH:mm').add(duration, 'm'))
        }
      }
      while (beginningTimeNoturno.isBefore(endTimeNoturno))

      return [timesMatutino, timesVespertino, timesNoturno]

    }

    const intervals = state.AppActiveUser.company.configuration && state.AppActiveUser.company.configuration.schedule && state.AppActiveUser.company.configuration.schedule.intervals ? state.AppActiveUser.company.configuration.schedule.intervals : []

    // MATUTINO
    const timesMatutino =  []
    const periodMatutinoStart = (state.AppActiveUser.company.configuration && state.AppActiveUser.company.configuration.schedule && state.AppActiveUser.company.configuration.schedule.period && state.AppActiveUser.company.configuration.schedule.period.matutino.start) ? state.AppActiveUser.company.configuration.schedule.period.matutino.start : '06:00'
    const periodMatutinoEnd = (state.AppActiveUser.company.configuration && state.AppActiveUser.company.configuration.schedule && state.AppActiveUser.company.configuration.schedule.period && state.AppActiveUser.company.configuration.schedule.period.matutino.end) ? state.AppActiveUser.company.configuration.schedule.period.matutino.end : '11:50'

    let beginningTimeMatutino = moment(periodMatutinoStart, 'HH:mm')
    const endTimeMatutino     = moment(periodMatutinoEnd, 'HH:mm')
    do {
      timesMatutino.push(`${beginningTimeMatutino.format('HH:mm')} ${moment(beginningTimeMatutino, 'HH:mm').add(duration, 'm').format('HH:mm')}`)
      const index = intervals.findIndex((o) => o.afterBlock === timesMatutino[timesMatutino.length - 1])
      if (index >= 0) {
        //existe um intervalo após este bloco, devo adicionar o intervalo para somar ao início do próx período
        beginningTimeMatutino = moment(moment(beginningTimeMatutino, 'HH:mm').add(parseInt(duration) + parseInt(moment.duration(intervals[index].time, 'HH:mm').asMinutes()), 'm'))
      } else {
        beginningTimeMatutino = moment(moment(beginningTimeMatutino, 'HH:mm').add(duration, 'm'))
      }
    }
    while (beginningTimeMatutino.isBefore(endTimeMatutino))

    // VESPERTINO
    const timesVespertino =  []
    const periodVespertinoStart = (state.AppActiveUser.company.configuration && state.AppActiveUser.company.configuration.schedule && state.AppActiveUser.company.configuration.schedule.period && state.AppActiveUser.company.configuration.schedule.period.vespertino.start) ? state.AppActiveUser.company.configuration.schedule.period.vespertino.start : '13:00'
    const periodVespertinoEnd = (state.AppActiveUser.company.configuration && state.AppActiveUser.company.configuration.schedule && state.AppActiveUser.company.configuration.schedule.period && state.AppActiveUser.company.configuration.schedule.period.vespertino.end) ? state.AppActiveUser.company.configuration.schedule.period.vespertino.end : '18:50'

    let beginningTimeVespertino = moment(periodVespertinoStart, 'HH:mm')
    const endTimeVespertino     = moment(periodVespertinoEnd, 'HH:mm')
    do {
      timesVespertino.push(`${beginningTimeVespertino.format('HH:mm')} ${moment(beginningTimeVespertino, 'HH:mm').add(duration, 'm').format('HH:mm')}`)
      const index = intervals.findIndex((o) => o.afterBlock === timesVespertino[timesVespertino.length - 1])
      if (index >= 0) {
        //existe um intervalo após este bloco, devo adicionar o intervalo para somar ao início do próx período
        beginningTimeVespertino = moment(moment(beginningTimeVespertino, 'HH:mm').add(parseInt(duration) + parseInt(moment.duration(intervals[index].time, 'HH:mm').asMinutes()), 'm'))
      } else {
        beginningTimeVespertino = moment(moment(beginningTimeVespertino, 'HH:mm').add(duration, 'm'))
      }
    }
    while (beginningTimeVespertino.isBefore(endTimeVespertino))

    // NOTURNO
    const timesNoturno =  []
    const periodNoturnoStart = (state.AppActiveUser.company.configuration && state.AppActiveUser.company.configuration.schedule && state.AppActiveUser.company.configuration.schedule.period && state.AppActiveUser.company.configuration.schedule.period.noturno.start) ? state.AppActiveUser.company.configuration.schedule.period.noturno.start : '19:40'
    const periodNoturnoEnd = (state.AppActiveUser.company.configuration && state.AppActiveUser.company.configuration.schedule && state.AppActiveUser.company.configuration.schedule.period && state.AppActiveUser.company.configuration.schedule.period.noturno.end) ? state.AppActiveUser.company.configuration.schedule.period.noturno.end : '23:50'

    let beginningTimeNoturno = moment(periodNoturnoStart, 'HH:mm')
    const endTimeNoturno     = moment(periodNoturnoEnd, 'HH:mm')
    do {
      timesNoturno.push(`${beginningTimeNoturno.format('HH:mm')} ${moment(beginningTimeNoturno, 'HH:mm').add(duration, 'm').format('HH:mm')}`)
      const index = intervals.findIndex((o) => o.afterBlock === timesNoturno[timesNoturno.length - 1])
      if (index >= 0) {
        //existe um intervalo após este bloco, devo adicionar o intervalo para somar ao início do próx período
        beginningTimeNoturno = moment(moment(beginningTimeNoturno, 'HH:mm').add(parseInt(duration) + parseInt(moment.duration(intervals[index].time, 'HH:mm').asMinutes()), 'm'))
      } else {
        beginningTimeNoturno = moment(moment(beginningTimeNoturno, 'HH:mm').add(duration, 'm'))
      }
    }
    while (beginningTimeNoturno.isBefore(endTimeNoturno))

    return [timesMatutino, timesVespertino, timesNoturno]
  }
}

export default getters
