import axios from '@/axios.js'

export default {
  fetchAll ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/accountPlans`, payload)
        .then((response) => {
          commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/accountPlan`, payload)
        .then((response) => {
          commit('SET', response.data)
          commit('ADD', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/accountPlan/${payload.id}`, payload)
        .then((response) => {
          commit('SET', response.data)
          commit('UPDATE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  delete ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/accountPlan/${id}`)
        .then((response) => {
          commit('DELETE', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }

}
