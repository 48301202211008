import createMutations from '@/store/model/mutations'
import model from './model'

const mutations =  createMutations(new model.constructor)

mutations.afterCloseSelect = function (state, payload) { // ao criar modelos relacionados: hasOne, hasMany
  payload.state.typePaymentSig.select['specie'].afterClose = payload.afterClose
  payload.state.financialCard.select['specie'].afterClose = payload.afterClose
}

export default mutations 