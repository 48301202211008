import { Model, configure } from '@/store/model/model'
import _ from 'lodash'

/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class AccountPlanSig extends Model {}

configure(model, {
  $fields: {
    company_id: {},
    created_at: {},
    deleted_at: {},
    id: {},
    parent_id: {},
    plan: {
      label: 'Nome',
      grid: true
    },
    updated_at: {}
  },
  $store: {
    name: 'accountPlanSig',
    actionName: 'financial/accountPlan',
    getters: {
      selectedparcels: 'selectedparcels',
      selectedcredit: 'selectedcredit'
    }
  },
  $select: {
    label: 'Plano de Conta',
    name: 'Conta',
    description: ['plan']
  },
  $grid: {
    actions: {
      edit: false
    }
  },
  $form: {
    disabled: true
  },
  $name: 'accountPlan'
})

export default new model()
