import state from './moduleTypePaymentState.js'
import mutations from './moduleTypePaymentMutations.js'
import actions from './moduleTypePaymentActions.js'
import getters from './moduleTypePaymentGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

