import createState from '@/store/model/state'
const state = createState()

state.select.interest = {
  disabled: false,
  isOpen: false,
  popup: false,
  selected: []
}

export default state
