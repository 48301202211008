import createMutations from '@/store/model/mutations'
import model from './model'

const mutations = createMutations(model)

mutations.command = function (state, payload) {
  state.command = payload
}

export default mutations
