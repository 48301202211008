import state from './moduleServicePackageState.js'
import mutations from './moduleServicePackageMutations.js'
import actions from './moduleServicePackageActions.js'
import getters from './moduleServicePackageGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

