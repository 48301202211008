<template>
  <div :class="classDiv1">
     <sig-button-info :text="observation" :title="label">
      <label for="" color="danger" class="vs-input--label">
        {{ label }}</label
      >
    </sig-button-info>
    <vs-switch v-model="localValue" :disabled="disabled">
      <span slot="off">{{onLabel}}</span> <span slot="on">{{offLabel}}</span>
    </vs-switch>

  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {},
    label: {
      default: 'Aceitar'
    },
    onValue: {},
    offValue: {},
    onLabel: {},
    offLabel: {},
    disabled: {
      default: false
    },
    observation: {},
    position: {}

  },
  data () {
    return {
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value === this.onValue
      },
      set (value) {
        this.$emit('input', value ? this.onValue : this.offValue)
      }
    },
    classDiv1 () {
      switch (this.position) {
      case 'bottom': return 'inline-flex flex-col-reverse bg-grey-lighter'
      case 'top': return 'inline-flex flex-col bg-grey-lighter'
      case 'left': return 'inline-flex flex-row bg-grey-lighter'
      case 'right': return 'inline-flex flex-row-reverse bg-grey-lighter'
      }
    }
  }
}
</script>
