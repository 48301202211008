<template>
  <vs-prompt
    :title="title"
    @cancel="$emit('cancel')"
    @accept="$emit('accept')"
    @close="$emit('close')"
    @update:active="$emit('update:active', $event)"
    :active="active"
    accept-text="Confirmar"
    cancel-text="Cancelar"
  >
    <slot></slot>
  </vs-prompt>
</template>

<script>
export default {
  props: {
    active: {},
    title:{}
  }
}
</script>
