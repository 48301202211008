import axios from '@/axios.js'

const control = {
  allowFetchOpenCashiers: true
}

export default {
  // LISTA CAIXAS ABERTOS
  fetchOpenCashiers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // evito multiplas chamadas simutaneas
      if (control.allowFetchOpenCashiers) {
        control.allowFetchOpenCashiers = false
        axios.post(`${process.env.VUE_APP_API_URL}/cashier/get/criteria`, { status: 'OPEN', notMe: false })
          .then((response) => {
            commit('REFRESH_OPEN_CASHIERS', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
          .finally(() => {
            control.allowFetchOpenCashiers = true
          })
      }
    })
  }
  //
}
