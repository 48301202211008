import { Model, configure } from '@/store/model/model'
import moment from 'moment'

/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class HttpCommand extends Model {}

configure(model, {
  $fields: {
    company_id: {},
    deleted_at: {},
    function: {},
    id: {},
    method: {},
    name: {
      label: 'Nome',
      width: 300,
      grid: true
    },
    created_at: {
      label: 'Data / Hora',
      cellRenderer: data => {
        return moment(data.value).format('DD/MM/YYYY HH:mm')
      },
      grid: true
    },
    params: {},
    updated_at: {},
    url: {}
  },
  $store: {
    name: 'httpCommand',
    actionName: 'httpCommand',
    commit: {
      command: 'command'
    }
  },
  $select: {
    name: 'VER HISTÓRICO',
    label: ''
  },
  $grid: {
    actions: {
      edit: true,
      select: false,
      delete: false,
      print: true
    }
  },
  $form: {
    disabled: true
  }
})

export default new model()
