import { Model, configure } from '@/store/model/model'
import typePayment from '../typePayment/model'
/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class Interest extends Model {
  constructor (args) {
    super(args)
  }
}

configure(model, {
  $fields: {
    value: {
      label: 'Valor Juros',
      default: 0,
      grid: true,
      format (element) {
        if (!element.value) return '0%'
        return element.type === '%' ? `${element.value.toLocaleString('pt-br')}%` : element.value.toLocaleString('pt-br')
      }
    },
    type: {
      label: 'Tipo',
      default: '%'
    },
    day: {
      label: 'Aplicar Juros em',
      default: 1
    },
    fine: {
      label: 'Valor de Multa',
      default: 0,
      grid: true,
      format (element) {
        if (!element.fine) return '0%'
        return element.type_fine === '%' ? `${element.fine  }%` : element.fine.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
      }
    },
    type_fine: {
      label: 'Tipo multa',
      default: '%'
    },
    fine_day: {
      label: 'Aplicar multa em',
      default: 1
    }
  },
  $relations: {
    type_payments: {
      model: typePayment,
      unset: true
    }
  },
  $store: {
    name: 'interest',
    actionName: 'financial/interest'
  },
  $select: {
    label: 'Configuração de Juros',
    name: 'Juros',
    description: []
  },
  $form: {
    name: 'Configuração de Juros'
  },
  $name: 'interest'
})

export default new model()
