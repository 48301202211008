import createState from '@/store/model/state'
const state = createState()
state.select.report = {
  disabled: false,
  isOpen: false,
  popup: false,
  selected: []
}

export default state
