import { Model, configure } from '@/store/model/model'
import _ from 'lodash'

/**
 * Lembre de registrar o store em financial/index.js
 */

const model = class Specie extends Model {}

// gerar field value
const value = {
  label: 'Tipo de Pagamento',
  enum: {
    TRANSFER: 'TRANSFERÊNCIA',
    PIX: 'PIX',
    CARNE: 'CARNÊ',
    ALL: 'TODAS',
    CREDIT_CARD: 'CARTÃO DE CRÉDITO',
    DEBIT_CARD: 'CARTÃO DE DÉBITO',
    BANKSLIP: 'BOLETO',
    MONEY: 'DINHEIRO',
    INDEFINIDO: ''
  },
  options: []
}

// gerar options para o value
_.each(value.enum, (element, key) => {
  value.options.push({
    label: element,
    value: key
  })
})

configure(model, {
  $fields: {
    value
  },
  $store: {
    name: 'specie',
    actionName: 'specie'
  },
  $name: 'specie',
  $select: {
    label: 'Selecione um Tipo de Pagamento'
  }
})

export default new model()
