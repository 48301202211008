import createState from '@/store/model/state'

const state = createState()

state.select.parcel = {
  disabled: false,
  isOpen: false,
  popup: false,
  selected: []
}

state.select.credit = {
  disabled: false,
  isOpen: false,
  popup: false,
  selected: []
}
export default state
