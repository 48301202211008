const dictionary = {
  messages: {
    dateLessOrEqualThan: (value, args) => {
      return `A ${value} deve ser menor ou igual a ${args[0]}` 
    },
    dateGreaterOrEqualThan: (value, args) => {
      return `A ${value} deve ser maior ou igual a   ${args[0]}` 
    },
    maxDate: (value, args) => {
      const type = args[2]
      const number = args[1]
      const otherValue = args[0]
      return `A ${value} deve ser no máximo ${number} ${type} maior que ${otherValue}` 
    }
  }
}

export default dictionary