/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

/**
 * ESCUTANDO EVENTOS DO LARAVEL
 */
window.io = require('socket.io-client')
import Echo from 'laravel-echo'
window.Echo = new Echo({
  broadcaster: 'socket.io',
  host: `${process.env.VUE_APP_URL}:6001`, //'http://dev.sigcfc.com.br:6001',
  auth: {
    headers: {
      /** I'm using access tokens to access  **/
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  }
})

// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// API Calls
import './http/requests'

// mock
import './fake-db/index.js'

// Theme Configurations
import '../themeConfig.js'


// ACL
import acl from './acl/acl'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// Vuexy Admin Filters
import './filters/filters'

import moment from 'moment'

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')

// VeeValidate
import VeeValidate, { Validator } from 'vee-validate'
import validationMessages from 'vee-validate/dist/locale/pt_BR'
import ptBr from './util/validator/locale/pt_BR'
import CpfValidator from './util/cpf.validator'
import validators from './util/validator'

import model from './store/register/user/model'

// registra validacao
for (const key of Object.keys(validators)) {
  Validator.extend(key, validators[key].validator, validators[key].params)
}

Object.assign(validationMessages.messages, ptBr.messages)

Validator.extend('cpf', CpfValidator)
Vue.use(VeeValidate, {
  i18nRootKey: 'validations', // customize the root path for validation messages.
  i18n,
  dictionary: {
    pt_Br: validationMessages
  }
})

import VCalendar from 'v-calendar'
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc'  // Use <vc-calendar /> instead of <v-calendar />
  // ...,                // ...other defaults
})

// "vue2-google-maps": "^0.10.6",
// Google Maps
// import * as VueGoogleMaps from 'vue2-google-maps'
// Vue.use(VueGoogleMaps, {
//   load: {
//     // Add your API key here
//     key: 'YOUR_KEY',
//     libraries: 'places' // This is required if you use the Auto complete plug-in
//   }
// })

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false
// Vue.config.devtools = false
Vue.prototype.$user = null

Vue.mixin({
  data () {
    return {
      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        consultApi: true
      }
    }
  },
  methods: {
    mixinNotify (message, color) {
      const msg = message ? message : 'Erro desconhecido. Contate o suporte.'
      this.$vs.notify({
        time: 4000,
        title: 'AVISO',
        text: msg,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color || 'success',
        position: 'top-center'
      })
    },
    mixinConfirmDialog (accept, text, title) {
      const tit = title ? title : 'Confirmar'
      const tex = text ? text : 'Tem certeza disso?'

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: tit,
        text: tex,
        accept,
        acceptText: 'Sim, tenho certeza',
        cancelText: 'Cancelar'
      })
    },
    mixinThen (func) {
      return () => {
        this.mixinNotify('Operação executada com sucesso')
        if (typeof func === 'function') {
          func()
        }
      }
    },
    mixinCatch (error) {
      if (error && error.response) {
        this.mixinNotify(error.response.data.message, error.response.status === 500 ? 'danger' : 'warning')
      } else {
        console.log(error)
      }
    },
    mixinFinally () {
      this.$vs.loading.close()
    },
    mixinCurrency (value) {
      return new Number(value).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
    },
    mixinLoadUser () {
      Vue.prototype.$user = new model.constructor(JSON.parse(localStorage.userInfo))
    },
    mixinToday () {
      return new Date().toLocaleDateString('en-CA')
    },
    mixinConvertDateStringUsToBr (date) {
      return date ? moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    }, // testa permissão de usuário
    mixinAllow (permission, like = false) {
      if (!permission) return true
      if (this.$user) {
        if (Array.isArray(this.$user.roles) && this.$user.roles.find(e => e.name === 'admin')) {
          // admin pode tudo
          return true
        }
        if (Array.isArray(this.$user.group_permissions)) {
          if (like) {
            // as vezes só ler uma parte da regra, o modulo ou a funcao
            return !!this.$user.group_permissions.find(e => e.includes(permission))
          }
          return this.$user.group_permissions.includes(permission)
        }
        return false
      }
      return true
    },
    mixinDenies (permission, like) {
      return !this.mixinAllow(permission, like)
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app')
