import createState from '@/store/model/state'

const state =  createState()

state.select.document = {
  disabled: false,
  isOpen: false,
  popup: false,
  selected: []
}
state.command = {
  url: '',
  params: {}
}
export default state
